import { NlsHandlerEventType } from '../speech/handler';

/**
 * 实时识别回调事件名
 */
export class RecognitionHandlerEventType extends NlsHandlerEventType {
  /** 开始识别 */
  started: string = 'started';
  /** 检测到了一句话的开始 */
  sentenceBegin: string = 'sentenceBegin';
  /** 中间识别结果 */
  sentenceChanged: string = 'sentenceChanged';
  /** 检测到了一句话的结束 */
  sentenceEnd: string = 'sentenceEnd';
  /** 结束识别 */
  completed: string = 'completed';
  /** 识别数据传输 */
  dating: string = 'dating';
}
