import NlsEvent, { NlsEventType } from '../speech/event';

/**
 * 实时识别事件类型
 */
export class RecognitionEventType extends NlsEventType {
  /** 识别开始 */
  RecognitionStarted: string = 'TranscriptionStarted';
  /** 服务端检测到了一句话的开始 */
  SentenceBegin: string = 'SentenceBegin';
  /** 中间识别结果 */
  RecognitionResultChanged: string = 'TranscriptionResultChanged';
  /** 服务端检测到了一句话的结束 */
  SentenceEnd: string = 'SentenceEnd';
  /** 结束识别 */
  RecognitionCompleted: string = 'TranscriptionCompleted';
}

/**
 * 实时识别事件
 */
export default class RecognitionEvent extends NlsEvent {
  /** @override 命名空间 */
  public namespace: string = 'SpeechTranscriber';

  /** @override 消息类型 */
  public type: RecognitionEventType = new RecognitionEventType();
}
