/**
 * 自定义错误信息
 */

export enum NLSErrorType {
  /** 未知错误 */
  UnknowError = -1,
  /** 录音错误 */ 
  RecordError = 10,
  /** Android 低版本 */
  PoolAndroid = 11,
  /** 不支持 AudioContext */
  NoAudioContext = 12,
  /** 不支持 MediaDevices */
  NoMediaDevices = 13,
  /** 不安全的环境 */
  NoSSL = 14,
  /** 网关消息格式错误 */
  DataFormatError = 15,
  /** 未知指令 */
  UnkownCommand = 16,
  /** 任务中 */
  TaskBusy = 17,
  /** 重连次数过多 */
  ReconnectOverTimes = 18,
  /** 缺少必要参数 */
  LostParam = 19,
  /** 不支持 WebAssembly */
  NoWebAssembly = 20,
  /** WS 初始化失败，可能 ws 连接有问题 */
  WSInitError = 21,
}

export default class NLSError extends Error {
  /** 错误码 */
  code: number = NLSErrorType.UnknowError;

  /**
   * 错误类
   * @param err 错误信息
   * @param code 错误码
   */
  constructor(err: string, code: number) {
    super(`[NLS Error] ${err}`);

    this.code = code;
  }
}
