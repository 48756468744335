/**
 * 消息头部
 */
export interface NlsMessageHeader {
  /** 消息全局唯一 id */
  message_id: string;
  /** 任务全局唯一 id */
  task_id: string;
  /** 消息所属的命名空间 */
  namespace: string;
  /** 消息名称 */
  name: string;
}

/**
 * 消息基类
 */
export default class NlsMessage {
  /** 消息头部 */
  header: NlsMessageHeader;
  /** 消息内容 */
  payload: any;
  /** 上下文信息 */
  context: any;

  /**
   * 构造函数
   * @param header 消息头
   * @param payload 消息内容
   * @param context 上下文
   */
  constructor(header: NlsMessageHeader, payload: any = null, context: any = null) {
    this.header = header;
    this.payload = payload;
    this.context = context;
  }

  /**
   * 消息序列化
   * @return 序列化后的字符串
   */
  serialize(): string {
    const msg: {
      header: NlsMessageHeader,
      payload?: any,
      context?: any,
    } = {
      header: this.header,
    };

    if (this.payload) {
      msg.payload = this.payload;
    }

    if (this.context) {
      msg.context = this.context;
    }
    return JSON.stringify(msg);
  }
}
