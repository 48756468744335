interface RapidFixRecognitionParams {
  key: string;
  type: string;
}

// 人机交互支持参数
const rapidFixrecognitionParams: RapidFixRecognitionParams[] = [{
  // 打卡词级别信息
  key: 'enableWordLevelResults',
  type: 'boolean',
}, {
  // 采样率
  key: 'sampleRate',
  type: 'number',
}, {
  // 格式
  key: 'format',
  type: 'string',
}, {
  // 热词
  key: 'vocabulary',
  type: 'object',
}, {
  // 泛热词ID
  key: 'vocabularyId',
  type: 'string',
}, {
  // 定制类热词
  key: 'classVocabularyId',
  type: 'object',
}, {
  // 模型
  key: 'model',
  type: 'string',
}, {
  // 定制模型
  key: 'customizationId',
  type: 'string',
}, {
  // 声学模型
  key: 'alsAmId',
  type: 'string',
}, {
  // 中间识别
  key: 'enableIntermediateResult',
  type: 'boolean',
}, {
  // 添加标点
  key: 'enablePunctuationPrediction',
  type: 'boolean',
}, {
  // 文本规整
  key: 'enableInverseTextNormalization',
  type: 'boolean',
}, {
  // 语义断句
  key: 'enableSemanticSentenceDetection',
  type: 'boolean',
}, {
  // 语音检测
  key: 'enableVoiceDetection',
  type: 'boolean',
}, {
  // 允许的最大开始静音，需先设置 enableVoiceDetection
  key: 'maxStartSilence',
  type: 'number',
}, {
  // 允许的最大结束静音，需先设置 enableVoiceDetection
  key: 'maxEndSilence',
  type: 'number',
}, {
  // 输出更多识别结果信息
  key: 'enableRichResult',
  type: 'boolean',
}, {
  // 自定义分段分隔符
  key: 'segmterStr',
  type: 'string',
}, {
  // 允许返回的 alternate 结果数量
  key: 'maxAlternates',
  type: 'number',
}, {
  // 是否开启转写文本后处理
  key: 'enableUnifyPost',
  type: 'boolean',
}, {
  // 统一后处理使用的模型
  key: 'unifyPostModelName',
  type: 'string',
}, {
  // 是否开启顺滑
  key: 'disfluency',
  type: 'boolean',
}, {
  // 超时时间，单位毫秒
  key: 'timeoutMillis',
  type: 'number',
}];

export default rapidFixrecognitionParams;
