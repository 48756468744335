import EventEmitter from 'eventemitter3';

import { NlsAuthentication, isTokenGetter } from '../../common/auth';
import WS from '../../common/ws';

import { getUUID } from '../../utils/uuid';
import NlsAudioPack from '../../protocol/nls-audio-pack';
import NlsCommandMessage from '../../protocol/nls-command-message';
import NlsEvent from './event';
import NlsEventMessage, { NlsEventMessageHeader } from '../../protocol/nls-event-message';
import Log from '../../utils/log';
import NLSError, { NLSErrorType } from '../../common/error';

import {
  NlsHandler,
  NlsHandlerEventType,
} from './handler';

/**
 * 语音功能基础类
 */
export default class Speech {
  /** 任务 id */
  public taskId: string;
  /** 事件触发器 */
  public eventBus: EventEmitter;

  /** 设备 id */
  protected deviceId: string;
  /** 应用 appkey */
  protected appkey: string;
  /** 事件管理器 */
  protected event: NlsEvent;
  /** 事件 handler */
  protected handler: NlsHandlerEventType;
  /** 网关地址 */
  private server: string;
  /** WebSocket 处理模块 */
  private ws: WS | null = null;
  /** token 设置 */
  private accessToken: string | NlsAuthentication.TokenGetter;
  /** @deprecated token 获取函数 */
  private getAccessToken?: (handler: NlsAuthentication.TokenHandler) => void;

  /**
   * 构造函数
   */
  constructor({
    /** 应用 appkey */
    appkey,
    /** 网关地址 */
    server,
    /** 设备 id */
    deviceId,
    /** token 配置 */
    accessToken,
    /** @deprecated token 获取函数 */
    getAccessToken,
  }: {
    appkey: string;
    server: string;
    deviceId: string;
    accessToken: string | NlsAuthentication.TokenGetter;
    getAccessToken?: (handler: NlsAuthentication.TokenHandler) => void;
  }) {
    this.appkey = appkey;
    this.server = server;
    this.deviceId = deviceId;
    this.taskId = getUUID();
    this.accessToken = accessToken;
    this.getAccessToken = getAccessToken;

    this.event = new NlsEvent();
    this.handler = new NlsHandlerEventType();
    this.eventBus = new EventEmitter();
  }

  /**
   * 设置回调函数
   * @param event 事件名
   * @param handler 事件处理函数
   */
  on(
    event: string,
    handler: NlsHandler,
    ) {
    Log.log('设置回调函数', event);
    this.eventBus.on(event, handler);
  }

  /**
   * 移除回调函数
   * @param event 事件名
   * @param handler 事件处理函数
   */
  off(
    event: string,
    handler?: NlsHandler,
    ) {
    Log.log('移除回调函数', event);
    this.eventBus.off(event, handler);
  }


  /**
   * 新建 session
   */
  protected startSession() {
    this.taskId = getUUID();
  }

  /**
   * 创建连接
   * @param token accessToken
   */
  private createWS = (token: string) => {
    Log.log('取到 token，准备连接');

    try {
      this.ws = new WS({
        server: this.server,
        token: token,
      });
      this.ws.onOpen = this.onConnected.bind(this);
      this.ws.onMessage = this.onData.bind(this);
      this.ws.onError = this.onError.bind(this);
      this.ws.onClose = this.onDisConnect.bind(this);
    } catch (err) {
      // ws 初始化失败
      this.onError(err, err.message, NLSErrorType.WSInitError);
    }
  }

  /**
   * 连接网关
   */
  protected connectService() {
    // 重建 session
    Log.log('重建 session');
    this.startSession();

    if (this.accessToken) {
      if (isTokenGetter(this.accessToken)) {
        this
          .accessToken()
          .then(this.createWS)
          .catch(e => {
            throw e;
          });
      } else {
        this.createWS(this.accessToken);
      }
    } else if (this.getAccessToken) {
      // 兼容老方法调用
      Log.warn('推荐使用 accessToken 替代 getAccessToken 配置 client');
      this.getAccessToken(this.createWS);
    } else {
      Log.warn('未配置 accessToken');
    }
  }

  /**
   * 断开网关
   */
  protected closeService() {
    Log.log('关闭连接');

    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  /**
   * 发送指令消息
   * @param message 指令消息
   */
  public sendMessage(message: NlsCommandMessage) {
    if (this.ws) {
      this.ws.send(message.serialize());
    }
  }
  
  /**
   * 发送语音流
   * @param message 语音数据包
   */
  protected sendAudio(message: NlsAudioPack) {
    if (this.ws) {
      this.ws.send(message.package());
    }
  }

  /**
   * 连接成功
   */
  protected onConnected() {
    Log.log('连接成功');
  }

  /**
   * 收到数据
   * @param message 消息内容
   * @param isArrayBuffer 是否为语音流消息
   */
  protected onData(message: any, isArrayBuffer: boolean = false) {    
    if (!isArrayBuffer) {
      const msg: NlsEventMessage = new NlsEventMessage(message.header, message.payload);

      // 连接失败
      if (this.event.isEvent(this.event.type.TaskFailed, msg)) {
        this.onError(
          message,
          (msg.header as NlsEventMessageHeader).status_text,
          (msg.header as NlsEventMessageHeader).status,
        );
        return;
      }

      // 未知消息
      Log.warn('----- 👇 未识别消息 -----');
      Log.log(msg);
      this.eventBus.emit(this.handler.unknow, msg);
    }
  }

  /**
   * 收到错误
   * @param error 错误内容
   * @param detail 错误详情
   * @param code 错误码，-1 为未知错误码
   */
  protected onError(
    error: Event,
    detail: string = '连接错误',
    code: number = NLSErrorType.UnknowError,
  ) {
    Log.error('Error Event', error, code, detail);

    const err: NLSError = new NLSError(detail, code);
    this.eventBus.emit(this.handler.error, err, err.message, err.code);
  }

  /**
   * 断开连接
   * @param ev 关闭消息
   */
  protected onDisConnect(ev: CloseEvent) {
    Log.log('连接已断开', 'Close Event', ev);
    this.eventBus.emit(this.handler.closed, ev);
  }
}
