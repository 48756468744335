import NlsCommandMessage from '../../protocol/nls-command-message';

/**
 * 指令类型
 */
export class NlsCommandType {}

/**
 * 识别指令协议
 */
export interface NlsCommandInterface {
  /** 指令类型 */
  type: NlsCommandType;

  /**
   * 获取指令消息
   * @param command 实时识别指令类型
   * @param extra 指令的额外参数
   * @return 指令消息
   */
  getCommand(command: string, extra?: any): NlsCommandMessage;
}

/**
 * 识别指令
 */
export default class NlsCommand {
  /** 命名空间 */
  protected namespace: string = 'Default';
  /** 设备 id */
  protected deviceId: string;
  /** 任务 id */
  protected taskId: string;
  /** 应用 appkey */
  protected appkey: string;

  /**
   * 构造函数
   */
  constructor({
    /** 设备 id */
    deviceId,
    /** 任务 id */
    taskId,
    /** 应用 appkey */
    appkey,
  }: {
    deviceId: string
    taskId: string,
    appkey: string,
  }) {
    this.deviceId = deviceId;
    this.taskId = taskId;
    this.appkey = appkey;
  }
}
