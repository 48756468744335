import NlsEvent, { NlsEventType } from '../speech/event';

/**
 * 实时识别事件类型
 */
export class RapidFixRecognitionEventType extends NlsEventType {
  /** 识别开始 */
  TaskStarted: string = 'TaskStarted';
  /** 中间识别结果 */
  ResultChanged: string = 'ResultChanged';
  /** 结束识别 */
  TaskCompleted: string = 'TaskCompleted';
}

/**
 * 实时识别事件
 */
export default class RecognitionEvent extends NlsEvent {
  /** @override 命名空间 */
  public namespace: string = 'RapidFixSpeechRecognizer';

  /** @override 消息类型 */
  public type: RapidFixRecognitionEventType = new RapidFixRecognitionEventType();
}
