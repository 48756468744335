import NlsCommandMessage from '../../protocol/nls-command-message';
import { getUUID } from '../../utils/uuid';
import NLSError, { NLSErrorType } from '../../common/error';
import NlsCommand, { NlsCommandType, NlsCommandInterface } from '../speech/command';
import { CustomCommand } from './plugin';
import { Recognition } from '../../nls-js-sdk';
import { hump2Line } from '../../utils/str';
import recognitionParams from './params';
import Log from '../../utils/log';

/**
 * 实时识别指令类型
 */
export class RecognitionCommandType extends NlsCommandType {
  /** 开始识别 */
  StartRecognition: string = 'StartTranscription';
  /** 停止识别 */
  StopRecognition: string = 'StopTranscription';
}

/**
 * 实时识别指令
 */
export default class RecognitionCommand extends NlsCommand implements NlsCommandInterface {
  /** @override 命名空间 */
  public namespace: string = 'SpeechTranscriber';

  /** 指令类型 */
  public type: RecognitionCommandType = new RecognitionCommandType();

  /** 识别实例 */
  private instance: Recognition;

  /** 配置格式化钩子 */
  private optionsFormat?: (config: any, settings: any) => any;

  /** 自定义命令钩子 */
  private parseCommand?: (
    command: string,
    extra: any,
    inst: Recognition,
  ) => CustomCommand | void;

  /**
   * 构造函数
   */
  constructor({
    /** 设备 id */
    deviceId,
    /** 任务 id */
    taskId,
    /** 应用 appkey */
    appkey,
    /** 识别实例 */
    instance,
    /** 配置格式化钩子 */
    optionsFormat,
    /** 自定义命令钩子 */
    parseCommand,
  }: {
    deviceId: string
    taskId: string,
    appkey: string,
    instance: Recognition,
    optionsFormat?: (config: any, settings: any) => any,
    parseCommand?: (command: string, extra: any, inst: Recognition) => CustomCommand | void,
  }) {
    super({
      deviceId,
      taskId,
      appkey,
    });

    this.instance = instance;
    this.optionsFormat = optionsFormat;
    this.parseCommand = parseCommand;
  }

  /**
   * 解析配置参数
   * @param extra 需要解析的参数
   * @param settings 默认配置
   * @return 解析后的参数
   */
  public parseOptions(extra: any, settings: any = {}): any {
    let config = Object.assign({}, settings);

    // 热词 [老，推荐使用 vocabulary] deprecated
    if (typeof extra.hotWords === 'object') {
      Log.warn('hotWords 参数不再被支持，推荐使用 vocabulary');
      config.vocabulary = extra.hotWords;
    }

    // 参数配置转化
    recognitionParams.forEach(_paramItem => {
      // number
      if (_paramItem.type === 'number'
        && typeof extra[_paramItem.key] === 'number') {
        config[hump2Line(_paramItem.key)] = extra[_paramItem.key];
      }

      // string
      if (_paramItem.type === 'string'
        && typeof extra[_paramItem.key] === 'string'
        && extra[_paramItem.key] !== '') {
        config[hump2Line(_paramItem.key)] = extra[_paramItem.key];
      }

      // object
      if (_paramItem.type === 'object'
        && typeof extra[_paramItem.key] === 'object') {
        config[hump2Line(_paramItem.key)] = extra[_paramItem.key];
      }

      // boolean
      if (_paramItem.type === 'boolean' && typeof extra[_paramItem.key] === 'boolean') {
        config[hump2Line(_paramItem.key)] = extra[_paramItem.key];
      }
    });

    // 获取钩子配置
    if (this.optionsFormat) {
      config = this.optionsFormat(extra, config);
    }

    return config;
  }

  /**
   * 获取指令消息
   * @param command 实时识别指令类型
   * @param extra 指令的额外参数
   * @return 指令消息
   */
  getCommand(command: string, extra?: any): NlsCommandMessage {
    switch(command) {
      // 开始识别
      case this.type.StartRecognition:
        const config = this.parseOptions(extra || {});

        return new NlsCommandMessage({
          task_id: this.taskId,
          message_id: getUUID(),
          namespace: this.namespace,
          name: this.type.StartRecognition,
          appkey: this.appkey,
        }, config, {
          device: {
            uuid: this.deviceId,
          },
        });

      // 结束识别
      case this.type.StopRecognition:
        return new NlsCommandMessage({
          task_id: this.taskId,
          message_id: getUUID(),
          namespace: this.namespace,
          name: this.type.StopRecognition,
          appkey: this.appkey,
        }, null, {
          device: {
            uuid: this.deviceId,
          },
        });

      default:
        // 获取自定义命令
        if (this.parseCommand) {
          const customCommand: CustomCommand | void = this.parseCommand(
            command,
            extra,
            this.instance,
          );

          if (customCommand) {
            return new NlsCommandMessage({
              task_id: this.taskId,
              message_id: getUUID(),
              namespace: this.namespace,
              name: customCommand.name,
              appkey: this.appkey,
            }, customCommand.data, {
              device: {
                uuid: this.deviceId,
              },
            });
          }
        }

        // 未知指令
        throw new NLSError(`undefined command: ${command}`, NLSErrorType.UnkownCommand);
    }
  }
}
