import { NlsAuthentication } from './common/auth';

import Recognition, {
  RecognitionOptions,
} from './core/recognition';
import RapidFixRecognition, {
  RapidFixRecognitionOptions,
} from './core/rapid-fix-recognition';
import Synthesis, {
  SynthesisOptions,
} from './core/synthesis';

import NlsEventMessage from './protocol/nls-event-message';
import RecognitionPluginInterface from './core/recognition/plugin';
import RapidFixRecognitionPluginInterface from './core/rapid-fix-recognition/plugin';

import { DetectorLevel, detector } from './utils/detect';
import Log from './utils/log';
import { getDeviceId } from './utils/device-id';

export {
  Log,
  NlsEventMessage,
  Recognition,
  RecognitionOptions,
  RecognitionPluginInterface,
  RapidFixRecognition,
  RapidFixRecognitionOptions,
  RapidFixRecognitionPluginInterface,
  Synthesis,
  SynthesisOptions,
};

/**
 * 语音 SDK 客户端
 */
export default class NlsClient {
  /** 语音应用 appkey */
  private appkey: string;
  /** 网关地址 */
  private server: string;
  /** 设备 id */
  private deviceId: string;
  /** accessToken 配置 */
  private accessToken: string | NlsAuthentication.TokenGetter;
  /** @deprecated 推荐使用 accessToken，token 获取函数 */
  private getAccessToken?: (handler: NlsAuthentication.TokenHandler) => void;
  /** 测试环境 */
  private isTestEnv: boolean;

  /**
   * 构造函数
   */
  constructor({
    /** 语音应用 appkey */
    appkey,
    /** 网关地址，可以自定义。默认公有云线上地址：wss://nls-gateway.cn-shanghai.aliyuncs.com/ws/v1 */
    server = 'wss://nls-gateway.cn-shanghai.aliyuncs.com/ws/v1',
    /** 设备 id */
    deviceId = getDeviceId(),
    /** token 设置，支持字符串或者获取函数，获取函数的方式为动态调用，每次需要使用 token 时，会通过获取函数获取最新 token */
    accessToken,
    /** @deprecated 推荐使用 accessToken。token 获取函数，每次需要使用 token 时，会通过获取函数获取最新 token */
    getAccessToken,
    /** 是否开启 debug 模式 */
    debug = false,
    /** 是否开启测试模式 */
    isTestEnv = false,
  } : {
    appkey: string;
    accessToken: string | NlsAuthentication.TokenGetter;
    getAccessToken?: (handler: NlsAuthentication.TokenHandler) => void;
    server?: string;
    deviceId?: string;
    debug?: boolean;
    isTestEnv?: boolean;
  }) {
    this.appkey = appkey;
    this.server = server;
    this.deviceId = deviceId;
    this.accessToken = accessToken;
    this.getAccessToken = getAccessToken;
    this.isTestEnv = isTestEnv;

    // 设置调试模式
    Log.setDebug(debug);
    Log.log(`应用ID: ${appkey}，网关地址: ${server}`);
  }

  /**
   * 初始化实时语音识别
   * @param options 配置参数
   * @param plugin 待加载的插件
   * @return 实时识别实例
   */
  initRecognition(
    options?: RecognitionOptions,
    plugin?: RecognitionPluginInterface,
  ): Recognition {
    if (!this.isTestEnv) {
      detector(DetectorLevel.DetectorLevel0);
    }

    const client: Recognition = new Recognition({
      appkey: this.appkey,
      server: this.server,
      deviceId: this.deviceId,
      accessToken: this.accessToken,
      getAccessToken: this.getAccessToken,
      plugin,
    }, options);
    return client;
  }

  /**
   * 初始化实时语音识别 v2
   * @param options 配置参数
   * @param plugin 待加载的插件
   * @return 实时识别实例
   */
  initRapidFixRecognition(
    options?: RapidFixRecognitionOptions,
    plugin?: RapidFixRecognitionPluginInterface,
  ): RapidFixRecognition {
    if (!this.isTestEnv) {
      detector(DetectorLevel.DetectorLevel0);
    }

    const client: RapidFixRecognition = new RapidFixRecognition({
      appkey: this.appkey,
      server: this.server,
      deviceId: this.deviceId,
      accessToken: this.accessToken,
      getAccessToken: this.getAccessToken,
      plugin,
    }, options);
    return client;
  }

  /**
   * 初始化合成语音
   * @param options 配置参数
   * @return 语音合成实例
   */
  initSynthesis(options?: SynthesisOptions): Synthesis {
    if (!this.isTestEnv) {
      detector(DetectorLevel.DetectorLevel1);
    }

    const client: Synthesis = new Synthesis({
      appkey: this.appkey,
      server: this.server,
      deviceId: this.deviceId,
      accessToken: this.accessToken,
      getAccessToken: this.getAccessToken,
    }, options);
    return client;
  }

  /**
   * 当前环境是否支持 SDK 所有功能正常运行
   * @return 是否支持
   */
  isSupport(): boolean {
    try {
      detector(DetectorLevel.DetectorLevel0);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * 当前环境是否支持 ASR（语音识别）正常运行
   * @return 是否支持
   */
  isSupportASR(): boolean {
    try {
      detector(DetectorLevel.DetectorLevel0);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * 当前环境是否支持 TTS（语音合成）正常运行
   * @return 是否支持
   */
  isSupportTTS(): boolean {
    try {
      detector(DetectorLevel.DetectorLevel1);
    } catch (e) {
      return false;
    }
    return true;
  }
}
