/**
 * 日志模块
 */
class Log {
  /** 是否为 debug 模式 */
  private isDebug: boolean = false;

  private getTag(tag: string = 'Debug') {
    return `[NLS ${tag} ${new Date().toISOString()}]`;
  }

  /**
   * 设置 debug 模式
   */
  setDebug(isDebug = false) {
    this.isDebug = isDebug;
    
    if (isDebug) {
      this.log('开启调试');
    }
  }

  /**
   * 打印日志
   * @param msg 日志信息
   */
  log(...msg: any[]) {
    if (this.isDebug) {
      console.log(this.getTag(), ...msg);
    }
  }

  /**
   * 打印警告
   * @param warn 
   */
  warn(...msg: any[]) {
    console.warn(this.getTag('Warning'), ...msg);
  }

  /**
   * 打印错误
   * @param error
   */
  error(...err: any[]) {
    console.error(this.getTag('Error'), ...err);
  }
}

export default new Log;
