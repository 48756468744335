/** 
 * 鉴权模块
 */
export declare namespace NlsAuthentication {
  /** @deprecated 推荐使用 TokenGetter，获取 token 的回调函数 */
  interface TokenHandler {
    /**
     * @deprecated 获取 token
     * @param token token 字符串
     */
    (token: string): void;
  }

  /** accessToken 获取函数 */
  interface TokenGetter {
    /**
     * 获取 token
     * @return Promise 回调 token 字符串
     */
    (): Promise<string>;
  }
}

/**
 * tokenGetter 类型守卫
 * @param value 待判断的值
 */
export function isTokenGetter(
  value: string | NlsAuthentication.TokenGetter,
): value is NlsAuthentication.TokenGetter {
  return typeof value !== 'string';
}
