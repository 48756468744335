import NlsEventMessage from '../../protocol/nls-event-message';

/**
 * 事件类型
 */
export class NlsEventType {
  /** 任务失败 */
  TaskFailed: string = 'TaskFailed';
}

/**
 * 公共事件
 */
export default class NlsEvent {
  /** 命名空间 */
  protected namespace: string = 'Default';

  /** 消息类型 */
  public type: NlsEventType = new NlsEventType();

  /**
   * 判断消息类型
   * @param type 公共事件类型
   * @param msg 待判断的事件消息
   * @return 判断结果
   */
  isEvent(type: string, msg: NlsEventMessage): boolean {
    // if (msg.header.namespace !== this.namespace) {
    //   return false;
    // }

    if (msg.header.name === type) {
      return true;
    }

    return false;
  }
}
