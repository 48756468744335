import { getUUID } from './uuid';

/**
 * 获取 deviceId
 * @return UUID
 */
export function getDeviceId(): string {
  const deviceIdKey = 'nls_uuid';
  let item = localStorage.getItem(deviceIdKey);
  if (!item) {
    item = `uu${getUUID()}`;
    localStorage.setItem(deviceIdKey, item);
  }

  return item;
}
