import NLSError, { NLSErrorType } from '../common/error';

/**
 * 探测级别
 */
export enum DetectorLevel {
  /**
   * mediaDevices、https（除以上还包含大于 level 0 的所有级别）
   */
  DetectorLevel0 = 0,
  /**
   * AudioContext（除以上还包含大于 level 1 的所有级别）
   */
  DetectorLevel1 = 1,
  /**
   * 系统支持度。（移动端存在支持 API，但是功能不正常的情况，手动排除掉）
   */
  DetectorLevel2 = 2,
}

/**
 * 浏览器环境探测
 * 判断环境是否支持 SDK 运行
 * @param level 探测级别
 */
export function detector(level: DetectorLevel = DetectorLevel.DetectorLevel0) {
  // level 2
  if (level <= DetectorLevel.DetectorLevel2) {
    // Android version < 7
    const version: number | null = getAndroidVersion();
    if (version !== null && version < 7) {
      throw new NLSError(
        'Android 版本无法正常运行语音 SDK，请使用 Android 7.0 及以上版本访问',
        NLSErrorType.PoolAndroid
      );
    }
  }

  // level 1
  if (level <= DetectorLevel.DetectorLevel1) {
    // AudioContext
    if (!((window as any).AudioContext || (window as any).webkitAudioContext)) {
      throw new NLSError('该浏览器不支持 AudioContext', NLSErrorType.NoAudioContext);
    }
  }

  // level 0
  if (level <= DetectorLevel.DetectorLevel0) {
    // 是否是不安全的环境
    // 不安全环境可能无法使用麦克风，导致 API 录制音频失败
    // getUserMedia() no longer works on insecure origins.
    // To use this feature, you should consider switching your application to a secure origin,
    // such as HTTPS. See https://goo.gl/rStTGz for more details.
    if (location.protocol === 'http:') {
      const host = location.host.split(':')[0];
      if (host !== 'localhost' && host !== '127.0.0.1') {
        throw new NLSError('请使用 ssl 协议保证环境安全，比如："https:"', NLSErrorType.NoSSL);
      }
    }

    // mediaDevices
    if (
      !(
        (navigator as any).webkitGetUserMedia ||
        (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
      )
    ) {
      throw new NLSError('该浏览器不支持 mediaDevices', NLSErrorType.NoMediaDevices);
    }

    // // webassembly
    // if (!(window as any).WebAssembly) {
    //   throw new NLSError('该浏览器不支持 WebAssembly', NLSErrorType.NoWebAssembly);
    // }
  }
}

/**
 * 获取 Android 版本号
 * @return Android 版本号
 */
export function getAndroidVersion(): number | null {
  const ua: string = navigator.userAgent.toLowerCase();
  let version: number | null = null;

  if (ua.indexOf('android') > 0) {
    const reg: RegExp = /android [\d._]+/gi;
    const vInfo: RegExpMatchArray | null = ua.match(reg);

    // 得到版本号4.2.2
    const versionStr: string = (vInfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.');
    // 得到版本号第一位
    version = parseInt(versionStr.split('.')[0], 10);
  }

  return version;
}

/**
 * 获取浏览器类型
 */
export function getBrowser(): string | undefined {
  const { userAgent } = window.navigator; // 取得浏览器的userAgent字符串
  const isOpera = userAgent.indexOf('Opera') > -1;

  if (isOpera) {
    return 'Opera';
  } // 判断是否Opera浏览器
  if (userAgent.indexOf('Firefox') > -1) {
    return 'FF';
  } // 判断是否Firefox浏览器
  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome';
  }
  if (userAgent.indexOf('Safari') > -1) {
    return 'Safari';
  } // 判断是否Safari浏览器
  if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) {
    return 'IE';
  } // 判断是否IE浏览器
}

/**
 * 判断浏览器音频采集是否支持指定采样率
 */
export function isSampleRateMediaSupport(): boolean {
  // 目前仅chrome浏览器支持AudioContext采样率和captureStream采样率不同
  // 其它浏览器中即使AudioContext支持自定义采样率，但是ctx.createMediaStreamSource方法仍报以下错误（例如FireFox）:
  // Connecting AudioNodes from AudioContexts with different sample-rate is currently not supported.
  if (getBrowser() !== 'Chrome') return false;

  const detectSampleRate = 16000;
  const AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;

  if (AudioContext) {
    const context = new AudioContext({
      sampleRate: detectSampleRate,
    });

    context.close();

    return context.sampleRate === detectSampleRate;
  }

  return false;
}

/**
 * 判断浏览器音频采集是否支持 WebAssembly
 */
export function isWebAssemblySupport(): boolean {
  // webassembly
  if (!(window as any).WebAssembly) {
    return false;
  }
  return true;
}
