import NlsEvent, { NlsEventType } from '../speech/event';

/**
 * 语音合成事件类型
 */
export class SynthesisEventType extends NlsEventType {
  /** 合成结束 */
  SynthesisCompleted: string = 'SynthesisCompleted';
  /** 播放结束 */
  SynthesisPlayEnded: string = 'SynthesisPlayEnded';
  /** MetaInfo 事件 */
  SynthesisMetaInfo: string = 'MetaInfo';
}

/**
 * 语音合成事件
 */
export default class SynthesisEvent extends NlsEvent {
  /** @override 命名空间 */
  protected namespace: string = 'SpeechSynthesizer';

  /** @override 消息类型 */
  public type: SynthesisEventType = new SynthesisEventType();
}
