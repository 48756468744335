import Speech from '../speech';
import { NlsAuthentication } from '../../common/auth';

import RapidFixRecognitionCommand from './command';
import RapidFixRecognitionEvent from './event';
import NlsEventMessage from '../../protocol/nls-event-message';
import { Recorder, ResamplerL16, TapeRecorder, appendBuffer } from '@ali/audio-kit';
import NlsAudioPack from '../../protocol/nls-audio-pack';
import Log from '../../utils/log';
import NLSError, { NLSErrorType } from '../../common/error';
import Resampler from '@ali/resampler';
import {
  RapidFixRecognitionHandlerEventType,
} from './handler';
import RapidFixRecognitionPluginInterface from './plugin';
import { isSampleRateMediaSupport, isWebAssemblySupport } from '../../utils/detect';

/**
 * 配置参数
 */
export interface RapidFixRecognitionOptions {
  /** 输入类型，mic(default)、audio */
  type?: string;
  /**
   * 输入源
   * type=mic，可选，deviceId string
   * type=audio，必选，audio 标签
   */
  input?: HTMLMediaElement | string;
  /** 热词，默认无热词 */
  hotWords?: Map<string, number>;
  /**
   * 模型。普通话通用 universal、普通话电商 e_commerce、
   * 普通话政法 law_politics、英文 english。默认不传模型，使用网关默认模型
   */
  model?: string;
  /** 是否开启中间识别，默认 false，不开启 */
  enableIntermediateResult?: boolean;
  /** 是否开启展示标点，默认 false，不开启 */
  enablePunctuationPrediction?: boolean;
  /** 是否开启文本规整，默认 false，不开启 */
  enableInverseTextNormalization?: boolean;
  /** 降采样 worker 地址，使用 pcm 格式传输数据时，可指定降采样模块 */
  resamplerWorker?: string;
  /** 降采样目标采样率。默认 16000 */
  sampleRate?: number;
  /** 是否开启语义断句 */
  enableSemanticSentenceDetection?: boolean;
  /** 语义断句分段的分隔符 */
  segmterStr?: string;
  /** 开启重连 */
  enableReConnect?: boolean;
}

/**
 * 人机协作
 */
export default class RapidFixRecognition extends Speech {
  /** 录音媒体对象 */
  public audioNode?: AudioNode;
  /** 录音媒体音频上下文 */
  public audioContext?: AudioContext;

  /** 指令管理器 */
  public command?: RapidFixRecognitionCommand;
  /** 事件管理器 */
  public event: RapidFixRecognitionEvent;
  /** 事件 handler */
  protected handler: RapidFixRecognitionHandlerEventType;
  /** 配置参数 */
  protected options: RapidFixRecognitionOptions = {
    type: 'mic',
    sampleRate: 16000,
  };

  /** 录音工具 */
  private recorder: Recorder | TapeRecorder | null = null;
  /** 可以接受语音流 */
  private isReady: boolean = false;
  /** 降采样模块 */
  private resampler: ResamplerL16 | null = null;
  private resamplerClang?: Resampler;
  /** 关闭锁，发送关闭消息，到收到结束 close socket 之间有一段时间，锁住待关闭完后再继续处理 */
  private closingLock: boolean = false;
  /** 关闭锁，延迟执行池子 */
  private closingLockDelayPoll?: () => void;

  /** 最大重连次数 */
  private maxReConnectTimes = 5;

  /** 重连剩余次数 */
  private reconnectTimesLast = this.maxReConnectTimes;

  /** 语音队列 */
  private chuckQueue: Uint8Array | null = null;

  /** 插件 */
  private plugin?: RapidFixRecognitionPluginInterface;

  /** 能否重连 */
  private canReconnect: boolean = true;

  /** 高级浏览器环境判断 */
  private highLevelBrowser: boolean = false; // 可以支持直接指定采样率录制

  /** 低级浏览器环境判断 */
  private lowLevelBrowser: boolean = false; // 不支持 WebAssembly 来降采样

  /**
   * 构造函数
   * @param options 配置参数
   */
  constructor(
    {
      /** 应用 appkey */
      appkey,
      /** 网关地址 */
      server,
      /** 设备 id */
      deviceId,
      /** token 配置 */
      accessToken,
      /** @deprecated token 获取函数 */
      getAccessToken,
      /** 流程插件 */
      plugin,
    }: {
      appkey: string;
      server: string;
      deviceId: string;
      accessToken: string | NlsAuthentication.TokenGetter;
      getAccessToken?: (handler: NlsAuthentication.TokenHandler) => void;
      plugin?: RapidFixRecognitionPluginInterface;
    },
    options?: RapidFixRecognitionOptions,
  ) {
    super({
      appkey,
      server,
      deviceId,
      accessToken,
      getAccessToken,
    });

    this.highLevelBrowser = isSampleRateMediaSupport();
    this.lowLevelBrowser = !isWebAssemblySupport();

    if (plugin) {
      Log.log('加载插件');
      this.plugin = plugin;
    }

    if (this.plugin && this.plugin.setup) {
      // call setup hook
      this.plugin.setup();
    }

    Log.log('SDK 配置', options);
    this.options = Object.assign(this.options, options || {});

    if (this.options.enableReConnect === false) {
      Log.log('关闭重连');
      this.maxReConnectTimes = 0;
      this.reconnectTimesLast = this.maxReConnectTimes;
    }

    this.event = new RapidFixRecognitionEvent();
    this.handler = new RapidFixRecognitionHandlerEventType();

    // 自定义事件
    this.setupEvent();

    // 自定义函数
    this.setupMethods();

    if (!this.highLevelBrowser
      && !this.lowLevelBrowser
      && this.options.resamplerWorker
    ) {
      // 指定了 resampler worker，使用 pcm clang 降采样模块
      Log.log('加载降采样 clang');
      this.resamplerClang = new Resampler(
        this.options.resamplerWorker,
        this.options.sampleRate || 16000,
        this.onRecordL16Data,
        {},
      );
    }
  }

  /** 获取 plugin */
  private getPlugin(): RapidFixRecognitionPluginInterface {
    return this.plugin || {};
  }

  /** 自定义 setup event */
  private setupEvent() {
    // setup event namespace
    const customNamespace = this.getPlugin().namespace;
    if (customNamespace) {
      this.event.namespace = customNamespace;
    }
    
    // setup event type
    const customEvent = this.getPlugin().event;
    if (customEvent) {
      if (customEvent.TaskStarted) {
        this.event.type.TaskStarted = customEvent.TaskStarted;
      }
      if (customEvent.ResultChanged) {
        this.event.type.ResultChanged = customEvent.ResultChanged;
      }
      if (customEvent.TaskCompleted) {
        this.event.type.TaskCompleted = customEvent.TaskCompleted;
      }
    }
  }

  /** 自定义 setup command */
  private setupCommand() {
    // setup command namespace
    const customNamespace = this.getPlugin().namespace;
    if (customNamespace && this.command) {
      this.command.namespace = customNamespace;
    }

    // setup command type
    const customCommand = this.getPlugin().command;
    if (customCommand && this.command) {
      if (customCommand.StartTask) {
        this.command.type.StartTask = customCommand.StartTask;
      }
      if (customCommand.StopTask) {
        this.command.type.StopTask = customCommand.StopTask;
      }
    }
  }

  /** 自定义 setup methods */
  private setupMethods() {
    // setup methods type
    const customMethods = this.getPlugin().methods;
    if (customMethods) {
      Object
        .keys(customMethods)
        .forEach(((fnName) => {
          const fn = customMethods[fnName];
          (this as any)[fnName] = function() {
            const args = Array.prototype.slice.apply(arguments);
            args.push(this);
            fn.apply(this, args);
          };
        }));
    }
  }

  /**
   * 原始数据类型可排队
   */
  private canQueue() {
    return true;
  }

  /**
   * 初始化 command
   */
  protected initCommand() {
    this.command = new RapidFixRecognitionCommand({
      deviceId: this.deviceId,
      taskId: this.taskId,
      appkey: this.appkey,
      optionsFormat: this.getPlugin().optionsFormat,
      parseCommand: this.getPlugin().parseCommand,
      instance: this,
    });

    // 自定义指令
    this.setupCommand();
  }

  /**
   * 开始识别
   */
  async start() {
    // 环境初始化
    await this.initEnv();

    // 清除队列
    this.chuckQueue = null;

    // 重置重连标示
    this.canReconnect = true;

    if (this.options.type === 'audio') {
      if (!(this.options.input instanceof HTMLMediaElement)) {
        throw new NLSError(
          'audio 模式 input 不能为空，必须要为 HTMLMediaElement 类型',
          NLSErrorType.LostParam,
        );
      }

      // audio 输入源
      this.recorder = new TapeRecorder({
        tape: this.options.input,
        sampleRate: this.options.sampleRate,
        onData: this.onRecordData,
        onError: this.onRecordError,

        onStart: () => {
          // 存储 audioNode 对象
          if (this.recorder && this.recorder.source) {
            Log.log('输出 audio 自定义对象', this.recorder.source, this.recorder.context);
            this.audioNode = this.recorder.source;
            this.audioContext = this.recorder.context;
          }

          // 开始连接
          Log.log('接入 audio 源');
          if (this.closingLock) {
            this.closingLockDelayPoll = () => this.connectService();
          } else {
            this.connectService();
          }

          // 初始化 command
          this.initCommand();
        },
        onStop: () => {
          // 结束连接
          Log.log('停止接入 audio 源');
          this.sendStopMessage();
        },
      });
    } else {
      // 自定义 mic 输入源
      const input: string | undefined = typeof this.options.input === 'string' ?
        this.options.input :
        undefined;

      // mic 输入源
      this.recorder = new Recorder({
        deviceId: input,
        sampleRate: this.options.sampleRate,
        onGetPermission: () => {
          // 存储 audioNode 对象
          if (this.recorder && this.recorder.source) {
            Log.log('输出 audio 自定义对象', this.recorder.source, this.recorder.context);
            this.audioNode = this.recorder.source;
            this.audioContext = this.recorder.context;
          }

          // 开始连接
          this.connectService();

          // 初始化 command
          this.initCommand();
        },
        onData: this.onRecordData,
        onError: this.onRecordError,
      });

      // 开始录音
      Log.log('开始录音');
      this.recorder.start();
    }
  }

  /**
   * 结束识别
   */
  stop() {
    // 停止录音，这里也停止一次，防止消息回不来，外部又不能手动停
    if (this.recorder instanceof Recorder) {
      Log.log('停止录音');
      this.recorder.stop();
    }

    if (this.recorder instanceof TapeRecorder) {
      Log.log('停止 Audio 绑定');
      this.recorder.destroy();
    }

    if (this.resamplerClang) {
      Log.log('停止 Resampler Worker');
      this.resamplerClang.destroy();
    }

    // 发送停止识别指令
    this.sendStopMessage();
  }

  /**
   * 设置音量增益大小
   * @param val 最小值约-3.4，最大约3.4
   */
  setGain(val: number) {
    if (this.recorder) {
      this.recorder.setGain(val);
    }
  }

  /**
   * 中途断开重连
   */
  protected restart(code = -1, msg = '未知原因') {
    Log.log('尝试重连...剩余次数', this.reconnectTimesLast, `[${code}] ${msg}`);
    this.eventBus.emit(this.handler.reconnect, code, msg, this.reconnectTimesLast);

    // 自定义 session update
    if (this.plugin && this.plugin.sessionReset) {
      this.plugin.sessionReset(this);
    }

    // 尝试重连
    if (this.reconnectTimesLast > 0) {
      setTimeout(() => {
        this.reconnectTimesLast -= 1;

        this.connectService();

        // 初始化 command
        this.initCommand();
      }, 100);
    } else {
      // 重连尝试次数过多，抛出错误
      Log.log('重连尝试次数过多，抛出错误', code);
      this.onError(
        new ErrorEvent(msg),
        msg,
        code,
      );
      this.reconnectTimesLast = this.maxReConnectTimes;
    }
  }

  /**
   * 初始化环境
   * @param callback 初始化完毕回调函数
   */
  private initEnv = () : Promise<void> => {
    if (this.resamplerClang) {
      return this.resamplerClang.init();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * 发送开始识别指令（网关连上后自动调用开始）
   */
  private sendStartMessage = () => {
    // 发送开始识别指令
    Log.log('发送开始识别指令，本次 taskId ', this.taskId);
    if (this.command) {
      const formatSettings = {
        format: 'pcm',
      };

      // 自定义启动参数
      const customOptions = this.getPlugin().defaultOptions || {};

      this.sendMessage(
        this.command.getCommand(this.command.type.StartTask, {
          ...this.options,
          ...formatSettings,
          ...customOptions,
        }),
      );
    }
  }

  /**
   * 发送停止识别指令
   */
  private sendStopMessage = () => {
    if (!this.isReady) {
      return;
    }

    // 发送停止识别指令
    Log.log('发送停止识别指令');
    this.isReady = false;
    this.closingLock = true;

    if (this.command) {
      this.sendMessage(this.command.getCommand(this.command.type.StopTask));
    }
  }

  /**
   * 录音数据回调
   * @param AudioBuffer 语音数据 audioBuffer
   */
  private onRecordData = (audioBuffer: AudioBuffer) => {
    if (this.highLevelBrowser) {
      // 支持直接指定采样率
      const buffer: Float32Array = audioBuffer.getChannelData(0);
      this.onRecordL16Data(this.floatTo16BitPCM(buffer));
    } else {
      if (this.resamplerClang) {
        // 使用 clang 模块降采样
        const buffers: Float32Array[] = [];
        for ( let i = 0; i < audioBuffer.numberOfChannels; i++ ) {
          buffers[i] = audioBuffer.getChannelData(i);
        }
        
        this.resamplerClang.feed(buffers);
      } else {
        // 初始化 resampler
        if (this.resampler === null) {
          this.resampler = new ResamplerL16({
            sourceSampleRate: audioBuffer.sampleRate,
            targetSampleRate: this.options.sampleRate,
            onReSample: this.onRecordL16Data,
          });
        }

        // 降采样
        this.resampler.downSample(audioBuffer);
      }
    }
  }

  /**
   * to 16bit
   */
  private floatTo16BitPCM = (buffer: Float32Array): Uint8Array => {
    const inPut16 = new DataView(new ArrayBuffer(buffer.length * 2)); 
    for (let i = 0; i < buffer.length; i++) {
      // 16-bit signed range is -32768 to 32767
      const multiplier = buffer[i] < 0 ? 0x8000 : 0x7fff;
      // index, value ("| 0" = convert to 32-bit int, round towards 0), littleEndian.
      inPut16.setInt16(i * 2, (buffer[i] * multiplier) | 0, true);
    }

    const chunk = new Uint8Array(inPut16.buffer.byteLength);
    chunk.set(new Uint8Array(inPut16.buffer), 0);

    return chunk;
  }

  /**
   * 目标采样数据回调
   * @param Uint8Array 语音数据 chunk
   */
  private onRecordL16Data = (chunk: Uint8Array) => {
    if (this.isReady) {
      // 检查队列
      if (this.chuckQueue) {
        if (this.chuckQueue.byteLength > 6500) {
          Log.log(`队列中有语音流数据，大小过大（${this.chuckQueue.byteLength}B），舍弃`);
        } else {
          Log.log('队列中有语音流数据，合并数据');
          const buffer: ArrayBuffer = appendBuffer(
            this.chuckQueue.buffer as ArrayBuffer,
            chunk.buffer as ArrayBuffer,
          );
          chunk = new Uint8Array(buffer);
        }

        // 清除队列
        this.chuckQueue = null;
      }

      // 发送语音流
      // Log.log('>>> 发送语音流');
      this.sendAudio(new NlsAudioPack(chunk));
      // 回调给外部
      this.eventBus.emit(this.handler.dating, chunk.buffer as ArrayBuffer);
    } else if (this.canQueue()) {
      Log.log('链接未 ready，语音流进队列');
      if (this.chuckQueue) {
        const buffer: ArrayBuffer = appendBuffer(
          this.chuckQueue.buffer as ArrayBuffer,
          chunk.buffer as ArrayBuffer,
        );
        this.chuckQueue = new Uint8Array(buffer);
      } else {
        this.chuckQueue = chunk;
      }
    }
  }

  /**
   * 录音错误回调
   * @param err 错误信息
   */
  private onRecordError = (err: Error) => {
    Log.error(err);

    const error: NLSError = new NLSError(
      '获取录音权限失败，无法使用语音识别功能',
      NLSErrorType.RecordError,
    );
    Log.error('录音错误', error);

    this.eventBus.emit(this.handler.error, error, error.message, error.code);
    throw error;
  }

  /**
   * 成功建连
   */
  protected onConnected() {
    super.onConnected();
    // 开始识别
    this.sendStartMessage();
  }

  /**
   * 收到事件消息
   * @param message 事件消息原始数据
   */
  protected onData(message: any) {
    const msg: NlsEventMessage = new NlsEventMessage(message.header, message.payload);

    // 处理自定义事件
    if (this.plugin && this.plugin.eventReceived) {
      const isContinue = this.plugin.eventReceived(msg, this);
      if (!isContinue) {
        return;
      }
    }

    // 开始识别
    if (this.event.isEvent(this.event.type.TaskStarted, msg)) {
      Log.log('开始识别');
      this.isReady = true;

      // 重置重连标示
      this.canReconnect = true;
      this.reconnectTimesLast = this.maxReConnectTimes;

      this.eventBus.emit(this.handler.started, msg.header.task_id, msg);
      return;
    }

    // 中间识别
    if (this.event.isEvent(this.event.type.ResultChanged, msg)) {
      Log.log(
        `中间识别，收到结果: \n
        ${msg.payload.sentence_end_result ? msg.payload.sentence_end_result.text : '<empty>'}|
        ${msg.payload.constant_partial_result ? msg.payload.constant_partial_result.text : '<empty>'}|
        ${msg.payload.variable_partial_result ? msg.payload.variable_partial_result.text : '<empty>'}\n`,
        `  【task_id】: ${msg.header.task_id}\n`,
      );

      this.eventBus.emit(
        this.handler.resultChanged,
        msg.payload,
        msg.header.task_id,
        msg,
      );
      return;
    }

    // 结束识别
    if (this.event.isEvent(this.event.type.TaskCompleted, msg)) {
      Log.log('结束识别');
      this.isReady = false;

      this.eventBus.emit(this.handler.completed, msg.header.task_id, msg);

      // 停止录音
      if (this.recorder instanceof Recorder) {
        this.recorder.stop();
      }
      // 断开连接
      this.closeService();

      this.closingLock = false;

      // 检查延迟处理事件
      if (this.closingLockDelayPoll) {
        this.closingLockDelayPoll();
        this.closingLockDelayPoll = undefined;
      }
      return;
    }

    super.onData(message);
  }

  /**
   * 收到错误消息
   * @param error 错误消息原始数据
   * @param detail 错误详情
   * @param code 错误码
   */
  protected onError(
    error: Event,
    detail: string,
    code: number | undefined,
  ) {
    // 停止当前识别
    this.isReady = false;

    if (code === undefined && !this.isReady) {
      if (this.canReconnect) {
        Log.log('无状态码连接断开', '准备重连');
        this.restart();
      }
    } else {
      this.canReconnect = false;
      this.stop();
      super.onError(error, detail, code);
    }
  }

  /**
   * 断开连接
   * @param ev 关闭消息
   */
  protected onDisConnect(ev: CloseEvent) {
    // 1006 异常断开，chrome 70 1006 断网断开还会返回 1000
    // 1006 是服务端主动断开，但是返回错误的情况下，服务端也会断开
    // 异常断开指服务端未返回错误，但是又断开 ws 链接的情况
    if ((ev.code === 1006 || ev.code === 1000) && this.isReady) {
      // 此时 ws 已经断开，收不到 stoped 事件，所以不需要 stop，直接重启 ws
      (ev as any)._isReconnect = true;
      
      // 停止当前识别
      this.isReady = false;
      Log.log(ev.code, '连接断开', '准备重连');
      this.restart(ev.code, '连接断开');
      return;
    }
    
    super.onDisConnect(ev);
  }
}
