/**
 * 语音数据包
 */
export default class NlsAudioPack {
  /** buffer */
  chunk: Uint8Array;
  
  /**
   * 构造函数
   * @param chunk 语音数据 buffer
   */
  constructor(chunk: Uint8Array) {
    this.chunk = chunk;
  }

  /**
   * 转化为语音流
   * @return 二进制包
   */
  package(): Blob {
    return new Blob([this.chunk], {
      type: 'audio/pcm',
    });
  }
}
