/**
 * 回调事件名
 */
export class NlsHandlerEventType {
  /** 错误信息 */
  error: string = 'error';
  /** 关闭消息 */
  closed: string = 'closed';
  /** 未知消息 */
  unknow: string = 'unknow';
  /** 重连 */
  reconnect: string = 'reconnect';
}

/**
 * 回调事件
 */
export interface NlsHandler {
  (...args: any[]): void;
}
