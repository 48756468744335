import { NLSErrorType } from './error';
import { addParam } from '../utils/query';

/**
 * WebSocket 处理模块
 */

/**
 * 内部 websocket 处理模块
 */
export declare namespace NlsWS {
  interface OnOpenHandler {
    /**
     * 建立链接
     */
    (): void;
  }

  interface OnMessageHandler {
    /**
     * 正常消息处理函数
     * @param message 消息内容
     * @param isArrayBuffer 是否为语音流消息
     */
    (message: any, isArrayBuffer: boolean): void;
  }

  interface OnErrorHandler {
    /**
     * 错误消息处理函数
     * @param error 消息内容
     * @param detail 错误详情
     * @param code 错误码，-1 为未知错误码
     */
    (error: Event, detail?: string, code?: number): void;
  }

  interface OnCloseHandler {
    /**
     * 链接关闭
     * @param ev 关闭事件消息
     */
    (ev: CloseEvent): void;
  }
}

export default class WS {
  /** 网关地址 */
  private server: string;
  /** 鉴权用的 accessToken */
  private token: string;
  /** WebSocket 连接 */
  private socket: WebSocket | null;
  /** error handler */
  private onErrorHandler: NlsWS.OnErrorHandler = () => {};

  /**
   * 构造函数
   */
  constructor({
    /** 网关地址 */
    server,
    /** 鉴权用的 accessToken */
    token,
  }: {
    server: string
    token: string,
  }) {
    this.server = server;
    this.token = token;

    this.socket = new WebSocket(addParam(this.server, 'token', this.token));
    this.socket.binaryType = 'arraybuffer';
  }

  /**
   * 发送数据
   * @param message 数据内容
   */
  send(message: string | Blob) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    }
  }

  /**
   * 关闭连接
   */
  close() {
    if (
      this.socket &&
      (this.socket.readyState !== WebSocket.CLOSED || this.socket.readyState !== WebSocket.CLOSING)
    ) {
      this.socket.close();
    }
  }

  /**
   * 设置建立连接回调函数
   * @param handler 建立连接回调函数
   */
  set onOpen(handler: NlsWS.OnOpenHandler) {
    if (this.socket) {
      this.socket.onopen = handler;
    }
  }

  /**
   * 设置正常消息处理回调函数
   * @param handler 正常消息处理回调函数
   */
  set onMessage(handler: NlsWS.OnMessageHandler) {
    if (this.socket) {
      this.socket.onmessage = (message: Event) => {
        if (message && (message as any).data) {
          if ((message as any).data instanceof ArrayBuffer) {
            // 语音流
            handler((message as any).data, true);
          } else {
            // 文本消息
            let dataObj: any;
            try {
              dataObj = JSON.parse((message as any).data);
            } catch (e) {
              this.onErrorHandler(message, '消息格式错误', NLSErrorType.DataFormatError);
            }

            if (dataObj) {
              handler(dataObj, false);
            }
          }
        }
      };
    }
  }

  /**
   * 设置错误消息处理回调函数
   * @param handler 错误消息处理回调函数
   */
  set onError(handler: NlsWS.OnErrorHandler) {
    if (this.socket) {
      this.onErrorHandler = handler;
      this.socket.onerror = (message: Event) => {
        handler(message, '连接失败。请确保 accessToken 有效并且网络正常。');
      };
    }
  }

  /**
   * 设置链接关闭回调函数
   * @param handler 链接关闭回调函数
   */
  set onClose(handler: NlsWS.OnCloseHandler) {
    if (this.socket) {
      this.socket.onclose = (message: CloseEvent) => {
        this.socket = null;
        handler(message);
      };
    }
  }
}
