/**
 * url 添加参数
 * @param url 待添加参数的 url
 * @param key 参数键
 * @param value 参数值
 * @return 添加参数后的 url
 */
export function addParam(url: string, key: string, value: string): string {
  let urlWithoutHash = url.split('#')[0];
  const val: string = encodeURIComponent(value);

  if (urlWithoutHash.indexOf('?') > -1) {
    const p = new RegExp(`(\\?|&)${key}=[^&]*`);
    if (p.test(urlWithoutHash)) {
      urlWithoutHash = urlWithoutHash.replace(p, `$1${key}=${val}`);
    } else {
      urlWithoutHash = `${urlWithoutHash}&${key}=${val}`;
    }
  } else {
    urlWithoutHash = `${urlWithoutHash}?${key}=${encodeURIComponent(val)}`;
  }
  return urlWithoutHash;
}
