import NlsMessage, {
  NlsMessageHeader,
} from './nls-message';

/**
 * 指令消息头
 */
export interface NlsCommandMessageHeader extends NlsMessageHeader {
  /** 应用 id */
  appkey: string;
}

/**
 * 指令消息
 */
export default class NlsCommandMessage extends NlsMessage {
  /**
   * 构造函数
   * @param header 指令消息头
   * @param payload 指令消息内容
   * @param context 上下文
   */
  constructor(header: NlsCommandMessageHeader, payload: any, context: any) {
    super(header, payload, context);
  }
}
