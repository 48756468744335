import NlsCommandMessage from '../../protocol/nls-command-message';
import { getUUID } from '../../utils/uuid';
import NLSError, { NLSErrorType } from '../../common/error';
import NlsCommand, { NlsCommandType, NlsCommandInterface } from '../speech/command';

/**
 * 语音合成指令类型
 */
export class SynthesisCommandType extends NlsCommandType {
  /** 开始合成 */
  StartSynthesis: string = 'StartSynthesis';
}

/**
 * 语音合成指令
 */
export default class SynthesisCommand extends NlsCommand implements NlsCommandInterface {
  /** 命名空间 */
  protected namespace: string = 'SpeechSynthesizer';

  /** 指令类型 */
  public type: SynthesisCommandType = new SynthesisCommandType();

  /**
   * 获取指令消息
   * @param command 语音合成指令类型
   * @param extra 指令的额外参数
   * @return 指令消息
   */
  getCommand(command: string, extra?: any): NlsCommandMessage {
    switch (command) {
      // 开始合成
      case this.type.StartSynthesis:
        return new NlsCommandMessage({
          task_id: this.taskId,
          message_id: getUUID(),
          namespace: this.namespace,
          name: this.type.StartSynthesis,
          appkey: this.appkey,
        }, {
          text: typeof extra.text === 'string' ? extra.text : '',
          voice:
            typeof extra.voice === 'string'
              ? extra.voice
              : 'aiqi',
          format: 'pcm',
          sample_rate: extra.sampleRate,
          volume: typeof extra.volume === 'number' ? extra.volume : 50,
          speech_rate: typeof extra.speechRate === 'number' ? extra.speechRate : 0,
          pitch_rate: typeof extra.pitchRate === 'number' ? extra.pitchRate : 0,
          enable_subtitle: typeof extra.enableSubtitle === 'boolean'
            ? extra.enableSubtitle
            : undefined,
        }, {
          device: {
            uuid: this.deviceId,
          },
        });

      default:
        throw new NLSError(`undefined command: ${command}`, NLSErrorType.UnkownCommand);
    }
  }
}
