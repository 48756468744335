import { NlsHandlerEventType } from '../speech/handler';

/**
 * 语音合成回调事件名
 */
export class SynthesisHandlerEventType extends NlsHandlerEventType {
  /** 合成完毕 */
  completed: string = 'completed';
  /** 可以播放，此状态在合成完毕前就会触发 */
  ready: string = 'ready';
  /** 开始播放 */
  play: string = 'play';
  /** 播放暂停 */
  paused: string = 'paused';
  /** 播放停止 */
  stoped: string = 'stoped';
  /** MetaInfo 信息 */
  metainfo: string = 'metainfo';
  /** 合成数据传输 */
  dating: string = 'dating';
}
