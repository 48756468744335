import NlsMessage, {
  NlsMessageHeader,
} from './nls-message';

/**
 * 事件消息头
 */
export interface NlsEventMessageHeader extends NlsMessageHeader {
  /** 状态码 */
  status: number;
  /** 状态消息 */
  status_text: string;
}

/**
 * 事件消息
 */
export default class NlsEventMessage extends NlsMessage {
  /**
   * 事件消息
   * @param header 事件消息头
   * @param payload 事件消息内容
   */
  constructor(header: NlsEventMessageHeader, payload: any = null) {
    super(header, payload);
  }
}
