import { NlsHandlerEventType } from '../speech/handler';

/**
 * 实时识别回调事件名
 */
export class RapidFixRecognitionHandlerEventType extends NlsHandlerEventType {
  /** 开始识别 */
  started: string = 'started';
  /** 中间识别结果 */
  resultChanged: string = 'resultChanged';
  /** 结束识别 */
  completed: string = 'completed';
  /** 识别数据传输 */
  dating: string = 'dating';
}
