import Speech from '../speech';
import { NlsAuthentication } from '../../common/auth';

import SynthesisCommand from './command';
import SynthesisEvent from './event';
import NlsEventMessage from '../../protocol/nls-event-message';
import { Player } from '@ali/audio-kit';
import Log from '../../utils/log';
import { getBrowser } from '../../utils/detect';
import {
  SynthesisHandlerEventType,
} from './handler';

// Safari下createBuffer只支持22.5k以上的采样率
const defaultSampleRate = getBrowser() === 'Safari' ? 24000 : 16000;

/**
 * 配置参数
 */
export interface SynthesisOptions {
  /** 是否自动播放，默认自动播放 */
  autoplay?: boolean;
  /** 发音人，可选项，默认 aiqi */
  voice?: string;
  /** 音量，取值范围 0 ~ 100，默认 50 */
  volume?: number;
  /** 语速，取值范围 -500 ~ 500，默认 0 */
  speechRate?: number;
  /** 语调，取值范围 -500 ~ 500， 默认 0 */
  pitchRate?: number;
  /** 是否开启字幕功能，默认为 false */
  enableSubtitle?: boolean;
}

/**
 * 语音合成
 */
export default class Synthesis extends Speech {
  /** 播放媒体对象 */
  public audioNode?: AudioNode;
  /** 播放媒体音频上下文 */
  public audioContext?: AudioContext;

  /** 待合成的字符串 */
  private text?: string;
  /** 指令管理器 */
  private command?: SynthesisCommand;
  /** 事件管理器 */
  protected event: SynthesisEvent;
  /** 事件 handler */
  protected handler: SynthesisHandlerEventType;
  /** 播放工具 */
  private player?: Player;
  /** 配置参数 */
  private options: SynthesisOptions = {
    autoplay: true,
    voice: 'aiqi',
    volume: 50,
    speechRate: 0,
    pitchRate: 0,
  };

  /**
   * 构造函数
   * @param options 配置参数
   */
  constructor(
    {
      /** 应用 appkey */
      appkey,
      /** 网关地址 */
      server,
      /** 设备 id */
      deviceId,
      /** token 配置 */
      accessToken,
      /** @deprecated token 获取函数 */
      getAccessToken,
    }: {
      appkey: string;
      server: string;
      deviceId: string;
      accessToken: string | NlsAuthentication.TokenGetter;
      getAccessToken?: (handler: NlsAuthentication.TokenHandler) => void;
    },
    options?: SynthesisOptions,
  ) {
    super({
      appkey,
      server,
      deviceId,
      accessToken,
      getAccessToken,
    });

    this.options = Object.assign(this.options, options || {});
    this.event = new SynthesisEvent();
    this.handler = new SynthesisHandlerEventType();
  }

  /**
   * 发送开始识别指令（网关连上后自动调用开始）
   */
  private sendStartMessage() {
    // 发送开始合成指令
    Log.log('发送开始识别指令，合成文本为：', this.text);

    if (this.command) {
      this.sendMessage(
        this.command.getCommand(this.command.type.StartSynthesis, {
          text: this.text,
          sampleRate: defaultSampleRate,
          ...this.options,
        }),
      );
    }
  }

  /**
   * 可以播放
   */
  private onPlayReady = () => {
    Log.log('已经可以开始播放合成结果');
    this.eventBus.emit(this.handler.ready, this.taskId);
  }

  /**
   * 开始播放
   */
  private onPlay = () => {
    // 存储 audioNode 对象
    if (this.player && this.player.source) {
      Log.log('输出 audio 自定义对象', this.player.source, this.player.context);
      this.audioNode = this.player.source;
      this.audioContext = this.player.context;
    }

    this.eventBus.emit(
      this.handler.play,
      this.taskId,
    );
  }

  /**
   * 播放暂停
   */
  private onPlayPause = () => {
    Log.log('播放器已经暂停');
    this.eventBus.emit(this.handler.paused, this.taskId);
  }

  /**
   * 播放停止
   */
  private onPlayStop = () => {
    Log.log('播放器已经停止');
    this.eventBus.emit(this.handler.stoped, this.taskId);
  }

  /**
   * 成功建连
   */
  protected onConnected() {
    super.onConnected();
    // 开始合成
    this.sendStartMessage();
  }

  /**
   * 开始合成（iOS 下会有自动播放限制，如果自动播放，此方法需要在用户主动点击事件内，否则会静音）
   * @param text 需要合成语音的文字
   */
  start(text: string) {
    // 重建 session
    this.startSession();

    this.text = text;
    this.command = new SynthesisCommand({
      deviceId: this.deviceId,
      taskId: this.taskId,
      appkey: this.appkey,
    });

    const autoplay = this.options.autoplay === false ? false : true;

    if (autoplay) {
      Log.log('当前播放设置为自动播放，将在收到合成结果后开始播放');
    } else {
      Log.log('当前播放设置为手动播放');
    }

    this.player = new Player({
      autoplay,
      onReady: this.onPlayReady,
      onPlay: this.onPlay,
      onPause: this.onPlayPause,
      onStop: this.onPlayStop,
      sampleRate: defaultSampleRate,
    });

    // 开始连接
    this.connectService();
  }

  /**
   * 开始播放（iOS 下会有自动播放限制，此方法需要在用户主动点击事件内，否则会静音）
   */
  play() {
    if (this.player) {
      Log.log('播放器播放');
      this.player.play();
    }
  }

  /**
   * 暂停播放
   */
  pause() {
    if (this.player) {
      Log.log('播放器暂停');
      this.player.pause();
    }
  }

  /**
   * 重置播放状态，从头开始播放
   */
  reset() {
    if (this.player) {
      Log.log('播放器回到播放起点');
      this.player.stop();
    }
  }

  /**
   * 停止合成，会终止当前未合成完毕的语音合成任务（如果需要重新播放，调用 reset）
   */
  stop() {
    Log.log('停止合成');
    // 合成完毕
    this.eventBus.emit(this.handler.completed, this.taskId);
    // 停止播放
    if (this.player) {
      this.player.destroy();
    }
    // 断开连接
    this.closeService();
  }

  /**
   * 收到事件消息
   * @param message 事件消息原始数据
   */
  protected onData(message: any, isArrayBuffer: boolean) {
    if (isArrayBuffer) {
      Log.log('<<< 收到合成结果');
      const buffer: ArrayBuffer = message;

      if (this.player) {
        this.player.feed(buffer);
      }

      // 回调给外部
      this.eventBus.emit(this.handler.dating, buffer);
    } else {
      const msg: NlsEventMessage = new NlsEventMessage(message.header, message.payload);

      // 合成完毕
      if (this.event.isEvent(this.event.type.SynthesisCompleted, msg)) {
        Log.log('合成完毕');
        // 已输入完所有 chunk
        if (this.player) {
          this.player.finish();
        }
        this.eventBus.emit(this.handler.completed, msg.header.task_id, msg);

        // 断开连接
        this.closeService();
        return;
      } else if (this.event.isEvent(this.event.type.SynthesisMetaInfo, msg)) {
        Log.log('收到 MetaInfo');

        this.eventBus.emit(this.handler.metainfo, msg.payload, msg.header.task_id, msg);
        return;
      }
    }

    super.onData(message, isArrayBuffer);
  }

  /**
   * 收到错误消息
   * @param error 错误消息原始数据
   * @param detail 错误详情
   * @param code 错误码
   */
  protected onError(
    error: Event,
    detail: string,
    code: number,
  ) {
    // 停止当前合成
    this.stop();
    
    super.onError(error, detail, code);
  }
}
